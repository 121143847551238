<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
	<div>
		<input
				:id="id"
				:autocomplete="autocomplete"
				:disabled="disabled"
				:inputmode="inputmode"
				:max="max"
				:min="min"
				:name="name"
				:placeholder="placeholder"
				:readonly="readonly"
				:step="step"
				:type="type"
				:value="modelValue"
				class="input-element"
				v-bind="$attrs"
				@blur="emitValue($event, true)"
				@input="emitValue($event, false)"
		/>
	</div>
</template>

<script lang="ts" setup>
	import {sharedFormElementProps, sharedFormInputProps} from '~/components/forms/sharedFormInputProps';

	const props = defineProps({
		...sharedFormElementProps,
		...sharedFormInputProps,
		modelValue: {
			type: [String, Number],
			default: null
		}
	});

	const emit = defineEmits(['update:modelValue']);

	defineOptions({
		inheritAttrs: false
	});

	/**
	 * Emit the value of the input element
	 * @param e The event that triggered the function
	 * @param trim Whether to trim the value before emitting
	 */
	const emitValue = (e: Event, trim = false) => {
		const targetElement = e.target as HTMLInputElement;
		let value: string | number | null = targetElement.value;

		if (trim) {
			value = value.trim();
		}

		if (props.type === 'number') {
			const valueAsNumber = targetElement.valueAsNumber;
			if (isNaN(valueAsNumber)) {
				value = null;
			} else {
				value = valueAsNumber;
			}
		}
		emit('update:modelValue', value);
	};
</script>

<style scoped>
	div {
		width: 100%;
		overflow: auto;
	}

	input[type="search"] {
		background-image: url('~/assets/icons/search.svg');
		background-size: 20px 20px;
		background-repeat: no-repeat;
		background-position: right 9px center
	}

	input[type="search"]::-webkit-search-cancel-button {
		padding: 0 30px 0 0;
	}
</style>
